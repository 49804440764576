import React from "react";
import Card from "./Card";
import { data } from "./data";

const Projects = () => {
  return (
    <div className="py-20 space-y-10">
      <div className="space-y-3">
        <h1 className="font-montserrat text-link text-center text-3xl font-black">
          Other Noteworthy Projects
        </h1>
        <p className="font-roboto-mono text-sm text-primary text-center">
          view the archive
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {data.map((item, i) => (
          <Card key={i} {...item} />
        ))}
      </div>
    </div>
  );
};

export default Projects;

import React from "react";
import { FaKissWinkHeart } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="text-center py-10 flex space-x-2 justify-center items-center w-full">
      <span className="font-roboto-mono text-xs text-link-dark">
        Design and build by Murtaza Asghar
      </span>
      <FaKissWinkHeart className="text-primary hover:text-link-dark transition duration-500 cursor-pointer" />
    </footer>
  );
};

export default Footer;

import React from "react";
import { Link } from "react-router-dom";
// @ts-ignore
import Fade from "react-reveal/Fade";
import Logo from "./Logo";

const Navbar = () => {
  return (
    <nav className="hidden sm:flex z-30 justify-between items-center font-work-sans h-20 sm:px-12">
      <Logo />
      <div className="flex justify-center text-primary items-center space-x-7">
        <Fade top distance="10px" duration={500}>
          <Link to="/" className="group">
            01.{" "}
            <span className="text-link transition duration-300 group-hover:text-primary text-sm">
              About
            </span>
          </Link>
        </Fade>
        <Fade top distance="10px" duration={1000}>
          <Link to="/" className="group">
            02.{" "}
            <span className="text-link transition duration-300 group-hover:text-primary text-sm">
              Experience
            </span>
          </Link>
        </Fade>
        <Fade top distance="10px" duration={1500}>
          <Link to="/" className="group">
            03.{" "}
            <span className="text-link transition duration-300 group-hover:text-primary text-sm">
              Work
            </span>
          </Link>
        </Fade>
        <Fade top distance="10px" duration={2000}>
          <Link to="/" className="group">
            04.{" "}
            <span className="text-link transition duration-300 group-hover:text-primary text-sm">
              Contact
            </span>
          </Link>
        </Fade>
        <Fade distance="30px" right>
          <button className="border border-primary px-3 py-2 text-sm transition-all hover:bg-primary hover:bg-opacity-20 duration-300 rounded-md">
            Resume
          </button>
        </Fade>
      </div>
    </nav>
  );
};

export default Navbar;

import React from "react";
import { Navbar } from "../../../components";
import Particles from "react-tsparticles";
import { data } from "../../../assets";
import UseAnimations from "react-useanimations";
import github from "react-useanimations/lib/github";
import instagram from "react-useanimations/lib/instagram";
import twitter from "react-useanimations/lib/twitter";
import linkedin from "react-useanimations/lib/linkedin";
import codepen from "react-useanimations/lib/codepen";

const Layout = ({ children }: { children: any }) => {
  return (
    <>
      <Particles
        className="bg-transparent z-20 hidden sm:block h-full"
        params={{
          particles: {
            number: {
              value: 30,
              density: {
                enable: true,
                value_area: 1000,
              },
            },
            line_linked: {
              enable: false,
            },
            move: {
              angle: {
                offset: 0,
                value: 90,
              },
              attract: {
                distance: 200,
                enable: false,
                rotate: {
                  x: 600,
                  y: 1200,
                },
              },
              decay: 0,
              distance: {},
              direction: "none",
              drift: 0,
              enable: true,
              path: {
                clamp: true,
                delay: {
                  random: {
                    enable: true,
                    minimumValue: 0,
                  },
                  value: 0,
                },
                enable: false,
              },
              outModes: {
                default: "out",
                bottom: "out",
                left: "out",
                top: "out",
                right: "out",
              },
            },
            shape: {
              type: ["images"],
              images: data.map((img) => ({
                src: img,
                height: 70,
                width: 70,
              })),
            },
            color: {
              value: "#000000",
            },
            size: {
              value: 30,
              random: false,
              anim: {
                enable: true,
                speed: 4,
                size_min: 10,
                sync: true,
              },
            },
          },
          retina_detect: false,
        }}
      />
      <div className="absolute top-0">
        <Navbar />
        <div className="flex justify-between items-start relative">
          <div className="w-14 sm:w-40 fixed left-0 h-screen">
            <div className="h-full pb-40 sm:pb-20 flex flex-col justify-center sm:justify-end items-center">
              <div className="flex flex-col justify-center items-center space-y-5 pb-6 text-link-dark">
                <a
                  href="https://github.com/murtaza-ch"
                  target="_blank"
                  rel="noreferrer"
                  className="transform hover:-translate-y-1 cursor-pointer transition-all duration-300 ease-in-out"
                >
                  <UseAnimations
                    animation={github}
                    size={30}
                    strokeColor="#8892B0"
                  />
                </a>
                <a
                  href="https://www.instagram.com/murtaza.azghar/"
                  target="_blank"
                  rel="noreferrer"
                  className="transform hover:-translate-y-1 cursor-pointer transition-all duration-300 ease-in-out"
                >
                  <UseAnimations
                    animation={instagram}
                    size={30}
                    strokeColor="#8892B0"
                  />
                </a>

                <a
                  href="/"
                  target="_blank"
                  rel="noreferrer"
                  className="transform hover:-translate-y-1 cursor-pointer transition-all duration-300 ease-in-out"
                >
                  <UseAnimations
                    animation={twitter}
                    size={30}
                    strokeColor="#8892B0"
                  />
                </a>

                <a
                  href="https://www.linkedin.com/in/murtaza-asghar/"
                  target="_blank"
                  rel="noreferrer"
                  className="transform hover:-translate-y-1 cursor-pointer transition-all duration-300 ease-in-out"
                >
                  <UseAnimations
                    animation={linkedin}
                    size={30}
                    strokeColor="#8892B0"
                  />
                </a>
                <a
                  href="/"
                  target="_blank"
                  rel="noreferrer"
                  className="transform hover:-translate-y-1 cursor-pointer transition-all duration-300 ease-in-out"
                >
                  <UseAnimations
                    animation={codepen}
                    size={30}
                    strokeColor="#8892B0"
                  />
                </a>
                <p className="transform block sm:hidden rotate-90 text-gray-400 pl-40 text-xs tracking-wider font-roboto-mono">
                  murtazach275@gmail.com
                </p>
              </div>
              <div className="w-0.5 hidden sm:block h-24 bg-gray-400"></div>
            </div>
          </div>
          <div className="w-full ml-14 mr-4 sm:mx-40">{children}</div>
          <div className="w-40 hidden sm:block fixed right-0 h-screen">
            <div className="h-full pb-20 flex flex-col justify-end items-center">
              <p className="transform rotate-90 text-gray-400 text-xs pb-1 pr-48 tracking-wider font-roboto-mono">
                murtazach275@gmail.com
              </p>
              <div className="w-0.5 h-24 bg-gray-400"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;

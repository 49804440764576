import React from "react";
import { BiRightArrow } from "react-icons/bi";

const About = () => {
  return (
    <div className="py-20 space-y-10">
      <div className="flex items-center justify-start">
        <span className="text-primary font-roboto-mono text-2xl pr-1.5">
          01.
        </span>
        <span className="text-2xl font-black text-link font-montserrat">
          About Me
        </span>
        <div className="h-0.05 w-2/6 ml-4 mt-1 bg-gray-700"></div>
      </div>

      <div className="grid w-full grid-cols-1 gap-6 sm:grid-cols-12">
        <div className="col-span-12 space-y-6 sm:col-span-7">
          <h1 className="font-inter text-link-dark">
            Hello! My name is Murtaza and I enjoy creating things that live on
            the internet. My interest in web development started back in 2012
            when I decided to try editing custom Tumblr themes — turns out
            hacking together a custom reblog button taught me a lot about HTML &
            CSS!
          </h1>
          <h1 className="font-inter text-link-dark">
            Fast-forward to today, and I've had the privilege of working at{" "}
            <span className="text-primary">
              an advertising agency, a start-up, a huge corporation, and a
              student-led design studio.
            </span>{" "}
            My main focus these days is building accessible, inclusive products
            and digital experiences at
            <span className="text-primary"> Scrapespot</span> for a variety of
            clients.
          </h1>
          <h1 className="font-inter text-link-dark">
            Here are a few technologies I've been working with recently:
          </h1>
          <div className="grid grid-cols-2 gap-2.5">
            <div className="flex space-x-1.5 items-center">
              <BiRightArrow className="text-primary" size={10} />
              <span className="text-xs font-inter sm:text-sm text-link-dark">
                JavaScript (ES6+)
              </span>
            </div>
            <div className="flex space-x-1.5 items-center">
              <BiRightArrow className="text-primary" size={10} />
              <span className="text-xs font-inter sm:text-sm text-link-dark">
                TypeScript (ES6+)
              </span>
            </div>
            <div className="flex space-x-1.5 items-center">
              <BiRightArrow className="text-primary" size={10} />
              <span className="text-xs font-inter sm:text-sm text-link-dark">
                React/Next/Vue Js
              </span>
            </div>
            <div className="flex space-x-1.5 items-center">
              <BiRightArrow className="text-primary" size={10} />
              <span className="text-xs font-inter sm:text-sm text-link-dark">
                React Native
              </span>
            </div>
            <div className="flex space-x-1.5 items-center">
              <BiRightArrow className="text-primary" size={10} />
              <span className="text-xs font-inter sm:text-sm text-link-dark">
                Redux/Redux Toolkit
              </span>
            </div>
            <div className="flex space-x-1.5 items-center">
              <BiRightArrow className="text-primary" size={10} />
              <span className="text-xs font-inter sm:text-sm text-link-dark">
                Node/Express/Nest Js
              </span>
            </div>
            <div className="flex space-x-1.5 items-center">
              <BiRightArrow className="text-primary" size={10} />
              <span className="text-xs font-inter sm:text-sm text-link-dark">
                AWS/GCP/Firebase
              </span>
            </div>
            <div className="flex space-x-1.5 items-center">
              <BiRightArrow className="text-primary" size={10} />
              <span className="text-xs font-inter sm:text-sm text-link-dark">
                MongoDb/SupaBase
              </span>
            </div>
            <div className="flex space-x-1.5 items-center">
              <BiRightArrow className="text-primary" size={10} />
              <span className="text-xs font-inter sm:text-sm text-link-dark">
                Unit Testing/Cypress
              </span>
            </div>
            <div className="flex space-x-1.5 items-center">
              <BiRightArrow className="text-primary" size={10} />
              <span className="text-xs font-inter sm:text-sm text-link-dark">
                Tailwind/Material Ui/Ant D
              </span>
            </div>
            <div className="flex space-x-1.5 items-center">
              <BiRightArrow className="text-primary" size={10} />
              <span className="text-xs font-inter sm:text-sm text-link-dark">
                Headless CMS
              </span>
            </div>
          </div>
        </div>
        <div className="col-span-12 px-4 sm:col-span-5 sm:px-10">
          <div className="relative group h-72">
            <div className="absolute w-full transition duration-300 transform border-2 rounded-md top-4 sm:top-5 left-4 sm:left-5 border-primary group-hover:-translate-y-2 group-hover:-translate-x-2 h-60 sm:h-72 "></div>
            <div className="absolute w-full bg-gray-700 bg-cover rounded-md bg-mee bg-blend-overlay group-hover:bg-blend-normal h-60 sm:h-72"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

import react from "./react.svg";
import g from "./graphql.svg";
import auth0 from "./auth0.svg";
import gitlab from "./gitlab.svg";
import mongo from "./mongo.svg";
import node from "./node.svg";
import aws from "./aws.svg";

export const data = [
  auth0,
  g,
  react,
  gitlab,
  mongo,
  node,
  aws,
  "https://uploads.codesandbox.io/uploads/user/ec666ae3-4cb4-4059-9c6a-d4a0650a1bf4/D6uF-d3-dot-js.svg",
  "https://uploads.codesandbox.io/uploads/user/ec666ae3-4cb4-4059-9c6a-d4a0650a1bf4/TMER-expo-cli.svg",
  "https://uploads.codesandbox.io/uploads/user/ec666ae3-4cb4-4059-9c6a-d4a0650a1bf4/Kmce-gatsby.svg",
  "https://uploads.codesandbox.io/uploads/user/ec666ae3-4cb4-4059-9c6a-d4a0650a1bf4/JUNF-go.svg",
  "https://uploads.codesandbox.io/uploads/user/ec666ae3-4cb4-4059-9c6a-d4a0650a1bf4/ig0D-mysql.svg",
  "https://uploads.codesandbox.io/uploads/user/ec666ae3-4cb4-4059-9c6a-d4a0650a1bf4/vvh2-neo4j.svg",
  "https://uploads.codesandbox.io/uploads/user/ec666ae3-4cb4-4059-9c6a-d4a0650a1bf4/TDNP-npm.svg",
  "https://uploads.codesandbox.io/uploads/user/ec666ae3-4cb4-4059-9c6a-d4a0650a1bf4/Byzl-zapier.svg",
  "https://simpleicons.org/icons/figma.svg",
  "https://simpleicons.org/icons/postgresql.svg",
  "https://simpleicons.org/icons/sentry.svg",
  "https://simpleicons.org/icons/github.svg",
];

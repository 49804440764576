import React, { useState } from "react";
import { BiRightArrow } from "react-icons/bi";
import { data } from "./data";

const Work = () => {
  const [active, setActive] = useState<any>("be");

  return (
    <div className="py-20 sm:px-20 space-y-10">
      <div className="flex justify-start items-center">
        <span className="text-primary font-roboto-mono sm:text-2xl pr-1.5">
          02.
        </span>
        <span className="text-link font-montserrat sm:text-2xl font-black">
          Where I’ve Worked
        </span>
        <div className="h-0.05 w-2/6 ml-1 sm:ml-4 mt-1 bg-gray-700"></div>
      </div>
      <div className="grid grid-cols-12 gap-y-4">
        <div className="col-span-12 flex sm:block overflow-x-auto sm:col-span-3 px-5 font-inter text-sm">
          <button
            onClick={() => setActive("be")}
            className={`${
              active === "be" ? "border-primary" : "border-navy"
            } h-10 w-full px-4 border-b-2 sm:border-b-0 sm:border-l-2 transition duration-700 cursor-pointer text-link-dark hover:text-primary hover:bg-navy flex justify-start items-center`}
          >
            Blueeast
          </button>
          <button
            onClick={() => setActive("moby")}
            className={`${
              active === "moby" ? "border-primary" : "border-navy"
            } h-10 w-full px-4 border-b-2 sm:border-b-0 sm:border-l-2 transition duration-700 cursor-pointer text-link-dark hover:text-primary hover:bg-navy flex justify-start items-center`}
          >
            MobyLogix
          </button>
          <button
            onClick={() => setActive("ss")}
            className={`${
              active === "ss" ? "border-primary" : "border-navy"
            } h-10 w-full px-4 border-b-2 sm:border-b-0 sm:border-l-2 transition duration-700 cursor-pointer text-link-dark hover:text-primary hover:bg-navy flex justify-start items-center`}
          >
            ScrapeSpot
          </button>
          <button
            onClick={() => setActive("com")}
            className={`${
              active === "com" ? "border-primary" : "border-navy"
            } h-10 w-full px-4 border-b-2 sm:border-b-0 sm:border-l-2 transition duration-700 cursor-pointer text-link-dark hover:text-primary hover:bg-navy flex justify-start items-center`}
          >
            COMSATS
          </button>
        </div>
        <div className="col-span-12 sm:col-span-9">
          <h1 className="text-link text-xl font-work-sans font-black">
            Engineer{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href={data[active].url}
              className="text-primary transition duration-400 hover:underline"
            >
              @ {data[active].title}{" "}
            </a>
          </h1>
          <p className="text-link-dark font-work-sans text-sm">
            {data[active].duration}
          </p>
          <div className="pt-4 space-y-2 sm:pr-14">
            {data[active].role.map((role: string, i: number) => (
              <div key={i} className="grid grid-cols-12">
                <div className="col-span-2 sm:col-span-1 flex justify-start pt-1.5 items-start">
                  <BiRightArrow className="text-primary" size={8} />
                </div>
                <div className="col-span-10 sm:col-span-11 text-xs sm:text-base flex -ml-6 justify-start items-start">
                  <span className="font-inter text-xs sm:text-sm text-link-dark">
                    {role}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;

import React from "react";

const Logo = () => {
  return (
    <svg
      // width="40"
      height="34"
      viewBox="0 0 643 475"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M283 1.10003C234.9 7.00003 176 42 124.6 95.1C54.6 167.4 7.50001 261.3 1.00001 341.5C-1.79999 376.4 5.90001 409.3 22.4 432.5C28.7 441.2 42.4 453.8 51.1 458.7C76.5 473.1 106.4 474.3 132.8 461.9C153.3 452.4 170.5 434.6 180.6 412.4C186.6 399.3 189.8 386.2 193.5 360.1C207.2 264.5 229.5 186.3 255.8 141.1C259 135.7 259.1 136.1 261.4 152.5C271 223.9 269.9 293.9 258.2 353C255 369.3 254.6 388.8 257.4 400.9C266.4 441.1 302.9 472.2 344 474.7C361.4 475.7 375.8 471.5 386.1 462.3C393.6 455.5 411.8 427.6 427.8 398.5C431.1 392.4 446.3 362.5 461.5 332C476.7 301.5 491.9 271.5 495.3 265.5C502.9 251.7 515.9 231 517 231C518.7 231 525.5 264.9 528.9 291C530.1 299.5 532.3 318.6 534 333.5C540.5 391 546.3 416.1 557.7 435.6C563.1 444.9 574.1 456.3 582 460.7C595 468.1 614.1 471.8 632.8 470.5L643 469.8L639 465.3C627.6 452.4 614.1 412.9 606.6 371C602.2 346.1 600.9 331.3 600.4 298C599.5 237.8 596.6 214.1 587.3 190.5C578.1 167.1 565.5 152.8 545.5 143C531.3 136 522.3 133.7 507.5 133.7C489.9 133.5 476.3 137.9 461.6 148.4C449.7 156.9 431.8 178 417.9 200L412.5 208.5L411.9 185.5C410.3 129.6 403.5 93 388.9 62.1C373.3 29.1 349.9 8.90003 320 2.40003C311.6 0.60003 292.5 -0.0999705 283 1.10003Z"
        fill="#64FFDA"
      />
    </svg>
  );
};

export default Logo;

export const data: any = {
  be: {
    url: "https://blueeast.com",
    tag: "be",
    title: "Blueeast",
    duration: "May 2018 - Present",
    role: [
      "Write modern, performant, maintainable code for a diverse array of client and internal projects",
      "Work with a variety of different languages, platforms, frameworks, and content management systems such as JavaScript, TypeScript, Gatsby, React, Craft, WordPress, Prismic, and Netlify",
      "Communicate with multi-disciplinary teams of engineers, designers, producers, and clients on a daily basis",
    ],
  },
  moby: {
    url: "https://mobylogix.com",
    tag: "moby",
    title: "MobyLogix",
    duration: "May 2018 - Present",
    role: [
      "Write modern, performant, maintainable code for a diverse array of client and internal projects",
      "Work with a variety of different languages, platforms, frameworks, and content management systems such as JavaScript, TypeScript, Gatsby, React, Craft, WordPress, Prismic, and Netlify",
      "Communicate with multi-disciplinary teams of engineers, designers, producers, and clients on a daily basis",
    ],
  },
  ss: {
    url: "http://scrapespot.com",
    tag: "ss",
    title: "ScrapeSpot",
    duration: "May 2018 - Present",
    role: [
      "Write modern, performant, maintainable code for a diverse array of client and internal projects",
      "Work with a variety of different languages, platforms, frameworks, and content management systems such as JavaScript, TypeScript, Gatsby, React, Craft, WordPress, Prismic, and Netlify",
      "Communicate with multi-disciplinary teams of engineers, designers, producers, and clients on a daily basis",
    ],
  },
  com: {
    url: "http://ww3.comsats.edu.pk/itcenter/",
    tag: "com",
    title: "COMSATS",
    duration: "2018 - 2019",
    role: [
      "Leveraged React JS resources for all data access, encapsulating URL’s and standardizing data access interfaces",
      "Extensive usage of Javascript, React JS, Node JS, Bootstrap, jQuery/AJAX, JSON, HTML4/5, CSS2/3.",
      "React and Functional Driven Javascript expert in SPA’s, UI Components, Mobile web and Performance optimization.Skilled in leading frameworks as React JS to build high-quality, scalable and reusable components and Front-end solution",
      " Responsible for implementing UI mockups, integrating third party React libraries, and handling/creating Redux store data.",
      "The back-end use of Node JS, Express JS, MongoDB and Mongoose, the complete MERN Stack to provide RESTful API.Implemented generating the verification hash code using JWT and added user validations and user permissions by using Node Js",
    ],
  },
};

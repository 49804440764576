import React from "react";

const Contact = () => {
  return (
    <div className="py-20 flex flex-col w-full justify-center items-center space-y-3 sm:space-y-6">
      <p className="font-work-sans text-primary text-center">
        04. What’s Next?
      </p>
      <h1 className="font-montserrat text-link sm:text-5xl font-black">
        Get In Touch
      </h1>
      <h1 className="font-inter text-center text-sm sm:text-base sm:px-44 text-link-dark">
        Although I'm not currently looking for any new opportunities, my inbox
        is always open. Whether you have a question or just want to say hi, I'll
        try my best to get back to you!
      </h1>
      <button className="border font-roboto-mono tracking-wider text-primary border-primary px-6 sm:px-8 py-3 sm:py-4 text-sm transition-all hover:bg-primary hover:bg-opacity-20 duration-300 rounded-md">
        Say Hello
      </button>
    </div>
  );
};

export default Contact;

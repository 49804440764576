import React from "react";

const Intro = () => {
  return (
    <div className="py-16 z-50">
      <div className="space-y-6">
        <h1 className="font-roboto-mono text-sm sm:text-base text-primary">
          My Name is
        </h1>
        <h1 className="font-montserrat text-link text-4xl sm:text-7xl font-black">
          Murtaza Asghar
        </h1>
        <h1 className="font-sora text-link-dark text-3xl sm:text-6xl font-black">
          I build things for the web.
        </h1>
        <h1 className="font-inter sm:pr-96 pb-3 text-link-dark">
          I'm a software engineer specializing in building (and occasionally
          designing) exceptional digital experiences. Currently, I'm focused on
          building accessible, human-centered products at{" "}
          <span className="text-primary">Scrapespot.</span>
        </h1>
        <button className="border font-roboto-mono tracking-wider text-primary border-primary px-6 py-3 sm:px-8 sm:py-4 text-sm transition-all hover:bg-primary hover:bg-opacity-20 duration-300 rounded-md">
          Get In Touch
        </button>
      </div>
    </div>
  );
};

export default Intro;

export const data = [
  {
    title: "Integrating Algolia Search with WordPress Multisite",
    desc: "Building a custom multisite compatible WordPress plugin to build global search with Algolia",
    tags: ["Algolia", "WordPress", "PHP"],
  },
  {
    title: "Time to Have More Fun",
    desc: "A single page web app for helping me choose where to travel, built with Next.js, Firebase, and Tailwind CSS",
    tags: ["Next.js", "Tailwind CSS", "Firebase"],
  },
  {
    title: "Building a Headless Mobile App CMS From Scratch",
    desc: "Find out how we built a custom headless CMS with Node, Express, and Firebase for a project at Upstatement",
    tags: ["Node", "Express", "Firebase", "React.js"],
  },
  {
    title: "Google Keep Clone",
    desc: "A simple Google Keep clone built with Vue and Firebase.",
    tags: ["React.js", "Firebase"],
  },
  {
    title: "Apple Music Embeddable Web Player Widget",
    desc: "Embeddable web player widget for Apple Music that lets users log in and listen to full song playback in the browser leveraging",
    tags: ["React.js", "Node", "Tailwind CSS"],
  },
  {
    title: "Personal Website V2",
    desc: "Second iteration of my personal website. Designed and developed with a conscious effort to avoid using any superfluous frameworks like Bootstrap.",
    tags: ["React.js", "Node", "Tailwind CSS"],
  },
];

import React from "react";
import { FiFolder } from "react-icons/fi";
import { HiOutlineExternalLink } from "react-icons/hi";
import { RiGithubLine } from "react-icons/ri";
// @ts-ignore
import Tilt from "react-tilt";

interface CardProps {
  title: string;
  desc: string;
  tags: string[];
}
const Card = ({ desc, tags, title }: CardProps) => {
  return (
    <Tilt
      className="bg-navy flex flex-col justify-between items-stretch space-y-4 rounded-md py-10 px-6"
      options={{ max: 12, scale: "100%" }}
    >
      <div className="flex justify-between items-center">
        <FiFolder size={40} className="text-primary" />
        <div className="flex items-center space-x-2">
          <RiGithubLine size={26} className="text-link" />
          <HiOutlineExternalLink size={30} className="text-link" />
        </div>
      </div>
      <div className="space-y-2 flex flex-col justify-start items-start h-full">
        <h1 className="text-link font-montserrat text-lg">{title}</h1>
        <p className="text-link-dark text-sm font-thin font-inter leading-5">
          {desc}
        </p>
      </div>
      <div className="flex items-center flex-wrap space-x-2.5">
        {tags.map((tag, i) => (
          <p
            key={i}
            className="text-link-dark text-xs font-thin font-roboto-mono leading-5"
          >
            {tag}
          </p>
        ))}
      </div>
    </Tilt>
  );
};

export default Card;

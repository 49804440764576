import React from "react";
import {
  Layout,
  Intro,
  About,
  Projects,
  Contact,
  Work,
  Footer,
} from "../../components";

const Home = () => {
  return (
    <Layout>
      <Intro />
      <About />
      <Work />
      <Projects />
      <Contact />
      <Footer />
    </Layout>
  );
};

export default Home;
